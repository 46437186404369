<template>
    <div>
        <CCard>
            <CCardBody>
                <b-row>
                    <b-col class="text-right">
                        <b-button @click.prevent="$router.go(-1)">{{ $t('buttons.prev') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="6" lg="4">
                        <h4>{{ $t('pages.content.accountManagement.headers.detail.main') }}</h4>
                        <b-table-simple responsive striped small>
                            <b-tbody>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.accountManagement.form.name', {language: $t('generally.languages.' + locale)}) }}:</b-td>
                                    <b-td>
                                        <img :src="data.image" alt="Logo" v-show="picture" width="20" height="20" @load="picture = true" @error="picture = false">
                                        <font-awesome-icon icon="user-lock" class="text-orange" v-show="!picture"/>
                                        {{ name[locale] }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.accountManagement.form.description', {language: $t('generally.languages.' + locale)}) }}:</b-td>
                                    <b-td>{{ description[locale] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.accountManagement.form.instancesExpireAt') }}:</b-td>
                                    <b-td>{{ $moment(data.instancesExpireAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.accountManagement.form.public') }}:</b-td>
                                    <b-td>
                                        <font-awesome-icon :class="data.public ? 'text-success' : 'text-danger'" :icon="data.public ? 'check' : 'times'"/>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('generally.owner') }}:</b-td>
                                    <b-td><span v-if="data.owner">{{ data.owner.firstName }} {{ data.owner.lastName }}</span></b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('generally.created') }}:</b-td>
                                    <b-td>{{ $moment(data.createdAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('generally.updated') }}:</b-td>
                                    <b-td>{{ $moment(data.updatedAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <h4>{{ $t('pages.content.accountManagement.form.instanceInfoTemplate') }}</h4>
                        <vue-codeditor style="font-size: 14px" v-model="data.instanceInfoTemplate" mode="html" theme="monokai" readonly/>
                    </b-col>
                    <b-col sm="12">
                        <hr>
                        <h4>{{ $t('pages.content.accountManagement.headers.detail.instances') }}</h4>
                        <b-row>
                            <b-col>
                                <b-button variant="orange" class="mr-1" size="sm" v-b-modal.accounts-detail-instances-modal>
                                    <font-awesome-icon icon="plus"/>
                                    {{ $t('pages.content.accountManagement.form.instances.button') }}
                                </b-button>
                            </b-col>
                            <b-col sm="4" md="5" xl="4" class="my-1">
                                <b-form-group
                                    label-for="filter-input"
                                    label-cols-sm="3"
                                    label-align-sm="right"
                                    label-size="sm"
                                    class="mb-0"
                                >
                                    <b-input-group size="sm">
                                        <b-form-input
                                            id="filter-input"
                                            v-model="filter"
                                            type="search"
                                            :placeholder="$t('generally.tables.tipToSearch')"
                                        ></b-form-input>

                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">{{ $t('generally.tables.clear') }}</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-table
                            small
                            striped
                            :empty-text="$t('generally.tables.emptyText')"
                            :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                            show-empty
                            :items="instances"
                            :fields="fields"
                        >
                            <template #cell(name)="row">
                                <font-awesome-icon icon="lock" v-if="row.item.locked"/>
                                <strike v-if="row.item.locked">
                                    {{ row.item.name }}
                                </strike>
                                <span v-else>
                                    {{ row.item.name }}
                                </span>
                            </template>
                            <template #cell(secret)="row">
                                <strike v-if="row.item.locked">
                                    {{ row.item.secret }}
                                </strike>
                                <span v-else>
                                    {{ row.item.secret }}
                                </span>
                            </template>
                            <template #cell(infoValues)="row">
                                <strike v-if="row.item.locked">
                                    {{ row.item.infoValues }}
                                </strike>
                                <span v-else>
                                    {{ row.item.infoValues }}
                                </span>
                            </template>
                            <template #cell(ownerId)="row">
                                <strike v-if="row.item.locked && row.item.owner">
                                    {{ row.item.owner.firstName }} {{ row.item.owner.lastName }}
                                </strike>
                                <span v-else-if="row.item.owner">
                                    {{ row.item.owner.firstName }} {{ row.item.owner.lastName }}
                                </span>
                            </template>

                            <template #cell(expiresAt)="row">
                                <strike v-if="row.item.locked">
                                    <span v-if="row.item.expiresAt">{{ $moment(row.item.expiresAt).format('DD.MM.YYYY HH:mm') }}</span>
                                </strike>
                                <span v-else>
                                    <span v-if="row.item.expiresAt">{{ $moment(row.item.expiresAt).format('DD.MM.YYYY HH:mm') }}</span>
                                </span>
                            </template>

                            <template #cell(action)="row">
                                <b-button class="ml-1" size="xs" variant="primary" v-b-modal.preview-json-modal @click="json = JSON.parse(row.item.infoValues)"><font-awesome-icon icon="code"/></b-button>
                                <b-button class="ml-1" size="xs" :variant="row.item.locked ? 'success' : 'orange'" @click="lockInstance(row.item)"><font-awesome-icon :icon="row.item.locked ? 'unlock' : 'lock'"/></b-button>
                                <b-button class="ml-1" size="xs" v-if="row.item.owner" variant="info" @click="resetInstance(row.item)"><font-awesome-icon icon="undo"/></b-button>
                                <b-button class="ml-1" size="xs" v-if="!row.item.owner && row.item.locked" variant="danger" @click="deleteInstance(row.item)"><font-awesome-icon icon="trash-alt"/></b-button>
                            </template>
                        </b-table>
                        <b-row>
                            <b-col>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="right"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <hr>
                <user-to-resource-list :data="data.users" attr="users_accounts"/>
            </CCardBody>
        </CCard>
        <b-modal
            id="accounts-detail-instances-modal"
            :ok-title="$t('buttons.create')"
            ok-variant="orange"
            @ok="addAccounts"
            :cancel-title="$t('buttons.cancel')"
            :title="$t('pages.content.accountManagement.form.instances.modal.title')"
        >
            <h4>{{ $t('pages.content.accountManagement.form.instances.modal.excelHeader') }}</h4>
            <b-form-group>
                <template #label>
                    {{ $t('pages.content.accountManagement.form.instances.modal.excel') }} <br>
                    <small v-html="$t('pages.content.accountManagement.form.instances.modal.excelDescription')"></small>
                </template>
                <b-form-textarea v-model="account.excel"></b-form-textarea>
            </b-form-group>
            <hr>
            <h4>{{ $t('pages.content.accountManagement.form.instances.modal.singleHeader') }}</h4>
            <b-form-group
                :label="$t('pages.content.accountManagement.form.instances.table.name')"
            >
                <b-input v-model="account.name"></b-input>
            </b-form-group>
            <b-form-group
                :label="$t('pages.content.accountManagement.form.instances.table.secret')"
            >
                <b-input v-model="account.secret"></b-input>
            </b-form-group>
            <b-form-group
                :label="$t('pages.content.accountManagement.form.instances.table.infoValues')"
            >
                <vue-codeditor style="font-size: 14px" v-model="account.infoValues" mode="json" theme="monokai"/>
            </b-form-group>
        </b-modal>
        <b-modal id="preview-json-modal" title="JSON" size="lg" ok-only>
            <vue-json-pretty :path="'res'" :data="json"></vue-json-pretty>
        </b-modal>
    </div>
</template>

<script>
import {EventBus} from "../../../services/EventBus";
import mainServices from "../../../services/mainServices";
import UserToResourceList from "../../../components/UserToResourceList";
import accountService from "../../../services/accountService";
import VueJsonPretty from "vue-json-pretty";
import 'vue-json-pretty/lib/styles.css';

export default {
    name: "Detail",
    components: {
        UserToResourceList,
        VueJsonPretty
    },
    props: ["id"],
    data: () => ({
        data: {},
        fields: [],
        name: {
            de: '',
            en: ''
        },
        description: {
            de: '',
            en: ''
        },
        image: '',
        instances: [],
        picture: '',
        locale: 'de',
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [5, 10, 15, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        account: {},
        json: ''
    }),
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.fields = [
            {key: "id", label: '', tdClass: 'align-middle text-center'},
            {key: "name", label: this.$t('pages.content.accountManagement.form.instances.table.name'), tdClass: 'align-middle'},
            {key: "secret", label: this.$t('pages.content.accountManagement.form.instances.table.secret'), tdClass: 'align-middle'},
            {key: 'infoValues', label: this.$t('pages.content.accountManagement.form.instances.table.infoValues'), tdClass: 'align-middle table-text-wrap'},
            {key: "expiresAt", label: this.$t('pages.content.accountManagement.form.instances.table.expireAt'), tdClass: 'align-middle'},
            {key: "ownerId", label: this.$t('pages.content.accountManagement.form.instances.table.assignedTo'), tdClass: 'align-middle'},
            {key: "action", label: '', tdClass: 'align-middle text-right'}
        ];
        this.loadData();
        this.loadInstances();
        this.initAccountForm();
        this.locale = mainServices.getLocale();
        // adding EventBus listener
        this.eventBus = EventBus.$on('sync', () => {
            this.$toastr.i(this.$t('generally.syncMessage'));
            this.loadData();
            this.loadInstances();
        });
    },
    beforeDestroy() {
        // removing EventBus listener
        EventBus.$off('sync', this.eventBus);
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        loadData() {
            accountService.get(this.id).then(response => {
                this.name = JSON.parse(response.data.name);
                this.description = JSON.parse(response.data.description);
                this.data = response.data;
            }).catch();
        },
        loadInstances(){
            accountService.getInstances(this.id).then(response => {
                this.instances = response.data;
                this.totalRows = response.data.length;
            }).catch(err => {
                this.errorHandler(err);
            })
        },
        addInstance(items){
            accountService.instanceAdd(this.id, items).then(response => {
                this.loadInstances();
                this.$toastr.s(this.$t('pages.content.accountManagement.messages.success.instances.created'))
                this.$nextTick(() => {
                    this.initAccountForm();
                    this.$bvModal.hide('accounts-detail-instances-modal');
                })
            }).catch(err => {
                this.errorHandler(err);
            });
        },
        resetInstance(item){
            accountService.instanceReset(item.id).then(response => {
                this.loadInstances();
                this.$toastr.s(this.$t('pages.content.accountManagement.messages.success.instances.reset'))
            }).catch(err => {
                this.errorHandler(err);
            });
        },
        lockInstance(item){
            accountService.instanceLock(item.id).then(response => {
                this.loadInstances();
                this.$toastr.s(this.$t('pages.content.accountManagement.messages.success.instances.locked'))
            }).catch(err => {
                this.errorHandler(err);
            });
        },
        deleteInstance(item){
            accountService.instanceDelete(item.id).then(response => {
                this.loadInstances();
                this.$toastr.s(this.$t('pages.content.accountManagement.messages.success.instances.deleted'))
            }).catch(err => {
                this.errorHandler(err);
            });
        },
        initAccountForm(){
            this.account = {
                excel: '',
                name: '',
                secret: '',
                description: ''
            }
        },
        addAccounts(bvModalEvt){
            bvModalEvt.preventDefault();
            if((this.account.excel === '' && this.account.name !== '' && this.account.infoValues !== '' && this.account.secret !== '') || (this.account.excel !== '' && this.account.name === '' && this.account.infoValues === '' && this.account.secret === '')){
                if(this.account.excel !== ''){
                    let data = [];
                    const lines = this.account.excel.split("\n");
                    for (const linesKey of lines) {
                        if(linesKey === '') break;
                        let row = linesKey.split("\t");
                        let jsonParse = false;
                        try {
                            JSON.parse(row[2]);
                            jsonParse = true;
                        } catch (e) {
                            console.log(e);
                        }
                        if (jsonParse) {
                            if (row.length === 3) {
                                data.push({
                                    name: row[0],
                                    secret: row[1],
                                    infoValues: JSON.stringify(JSON.parse(row[2]))
                                });
                            } else {
                                this.$toastr.e(this.$t('pages.content.accountManagement.messages.error.badTableFormat'));
                                return;
                            }
                        } else {
                            this.$toastr.e(this.$t('pages.content.accountManagement.messages.error.invalidJSON'));
                            return;
                        }
                        this.addInstance(data);
                    }
                } else {
                    let jsonParse = false;
                    try {
                        JSON.parse(this.account.infoValues);
                        jsonParse = true;
                    } catch (e) {
                        console.log(e);
                    }
                    if (jsonParse) {
                        this.addInstance([{
                            name: this.account.name,
                            secret: this.account.secret,
                            infoValues: JSON.stringify(JSON.parse(this.account.infoValues))
                        }]);
                        this.$nextTick(() => {
                            this.$bvModal.hide('accounts-new-instances-modal');
                        });
                    } else {
                        this.$toastr.e(this.$t('pages.content.accountManagement.messages.error.invalidJSON'));
                        return;
                    }
                }
            } else {
                this.$toastr.e(this.$t('pages.content.accountManagement.messages.error.addAccount'));
            }
        },
    }
};
</script>

<style>
.table-text-wrap {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
